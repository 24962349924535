import { getMenuId, menuComponentName } from '~/store/storyblok'

export default {
  mounted () {
    if (this.$storybridge) {
      this.$storybridge.on(['input', 'published', 'change'], (event) => {
        if (event.action === 'input') {
          // Inject content on the input event
          if (event.story.id === this.page.id) {
            const id = event.story.content.component === menuComponentName
              ? getMenuId(event.story.slug)
              : this.getUrl()
            this.$store.commit('storyblok/story', {
              id,
              data: event.story
            })
          }
        } else if (!event.slugChanged) {
          // Reload the page on save events
          window.location.reload()
        }
      })
    }
  }
}
